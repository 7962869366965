@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,700');
$primary: #4fa1cb;
/* MAIN COLOR */

$secondary: #b724ce;
/* SECONDARY COLOR */

$gray: #666;
/* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */

$blk: #333;
/* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */

$wht: #fafafa;
$footerLinks: #d5d5d5;
/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}



body {
    font-size: 1.5em;
    font-family: 'Raleway', sans-serif;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-size: 1.6em;
        line-height: 1.4em;
        text-transform: uppercase;
        letter-spacing: 1px;
        @media(max-width: 767px) {
            font-size: 1.4em;
        }
    }
    p {
        font-size: 1.2em;
        line-height: 1.7em;
        font-weight: 300;
        @media(max-width: 767px) {
            font-size: 1em;
        }
    }
}

nav {
    z-index: 10;
}

.navbar-brand {
    height: 100px; // this is what determine the height of the nav
    padding: 5px;
    @media(max-width: 767px) {
        height: 70px; // nav height mobile,(feel free to change this values as you please)
        .logo {
            max-height: 60px; // logo height changed on mobile
        }
    }
}

.navbar .navbar-nav {
    >li>a {
        text-align: center;
        margin-top: 22px;
        display: flex;
        align-items: center;
        color: black;
        transition: .2s all ease;
        @media (max-width: 767px) {
            margin-top: 0;
            display: inline-block;
            height: 25px;
            padding-top: 3px;
            padding-bottom: 3px;
        }
        &:hover {
            background: $primary;
            color: $wht;
            @media (max-width: 1024px) {
                background: transparent;
                color: $blk;
                outline: 0;
            }
        }
        &:focus,
        &:active {
            background: transparent;
            color: $blk;
            outline: 0;
        }
    }
}

.navbar-toggle {
    margin: 18px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}
.navbarFixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}
// here start mixins ---- this is a style for buttons you can add it to your a tag like this (@include btn, look at the .btn-default style), these values can be changed anytime you need to.
@mixin btn {
    border: 1px solid $wht;
    border-radius: 0px;
    background: none;
    color: $wht;
    padding: 1em 2.5em;
    font-size: 1em;
    font-weight: 300;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    display: inline-block;
    margin: 1em 0em;
    background: $secondary;
    &:hover {
        background: $primary;
        color: $wht; // box-shadow: 0px 0px 3px lighten($blk,10%);
        text-decoration: none;
        @media(max-width: 1024px) {
            background: none;
            border: 1px solid $wht;
        }
    }
}

.btn-default {
    @include btn;
}

// mixins end here
// sections start
section {}

.quote {
    padding: 150px 0;
    font-size: 1em;
    font-style: italic;
    text-transform: lowercase;
    text-align: center;
    line-height: 2em;
    @media(max-width: 767px) {
        padding: 50px 0;
    }
}

.hero-slider {
    background-color: darken($secondary, 20%);
    padding: 60px 0;
    color: $wht;
}

.section-a {
    background-color: lighten($blk, 75%);
    padding: 90px 0;
    @media(max-width: 767px) {
        padding: 50px 0;
    }
    a {
        @include btn;
    }
}

.section-b {
    background-color: lighten($primary, 35%);
    padding: 60px 0;
    a {
        @include btn;
    }
    .col-md-6 {
        padding-bottom: 25px;
    }
}

.section-c {
    padding: 80px 0;
    background-color: darken($secondary, 5%);
    color: $wht;
    a {
        @include btn;
    }
}

.section-d {
    padding: 80px 0;
    background-color: lighten($primary, 35%);
}

.section-e {
    padding: 80px 0;
    ul {
        padding: 0;
        li {
            list-style: none;
            line-height: 2.5em;
            a {
                text-decoration: none;
                color: $blk;
            }
            i {
                font-size: 2em;
                margin-right: 20px;
                color: $primary;
                max-width: 20px;
            }
        }
    }
}

// sections end
.modal-dialog {
    max-width: 500px;
    width: 100%;
    text-align: center;
    margin: 6em auto;
    .close {
        display: none;
    }
    .modal-content {
        color: $primary;
        h2 {
            text-align: center;
        }
    }
    a.btn,
    a.btn-default,
    button {
        @include btn margin: 0;
        display: block;
    }
}

.modal-header,
.modal-footer {
    background: $primary;
    color: $wht;
}

.modal-open {
    overflow: auto;
    padding-right: 0px !important;
}

footer {
    padding: 50px 0px 20px;
    background: $secondary;
    color: $wht;
    font-size: 15px;
    a {
        color: $wht;
        white-space: nowrap;
        font-size: 15px;
        &:hover {
            color: lighten($wht, 10%);
            outline: 0 !important;
            text-decoration: none;
        }
        &:focus {
            color: $wht;
            outline: 0 !important;
            text-decoration: none;
        }
    }
    p {
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

.logo {
    max-width: 250px;
    padding: 0.5em;
}

@media(max-width: 767px) {
    .logo {
        max-width: 175px;
    }
    .navbar-header a {
        float: left;
    }
}